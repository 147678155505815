import React, { useContext } from "react";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
//import landingPerson from "../../assets/lottie/landingPerson";
import titlePerson from "../../assets/lottie/titlePerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";

import { illustration, greeting } from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";


//import { Typewriter } from 'react-simple-typewriter';
import Typewriter from 'react-typewriting-animation';


export default function Greeting() {
  const { isDark } = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }

  const GetDescBullets = ({ descBullets }) => {
    return descBullets
      ? descBullets.map((item, i) => (
        <li key={i} className="subTitle">
          {item}
        </li>
      ))
      : null;
  };

  

  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className={isDark ? "dark-mode greeting-text" : "greeting-text"}
              >
                {greeting.title}
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {/* {greeting.subTitle} */}
                <GetDescBullets descBullets={greeting.subTitle} />

                {/* <Typewriter
                    rotateSpeed={1500}
                    typeSpeed={100}
                    fontSize={'32px'}
                    fontFamily={'Montserrat'}
                    color={isDark
                      ? '#FFFFFF'
                      : '#868e96'}
                    heading={'CAM'}
                    dataText={['SprutCAM и SprutCAM Robot', 'PowerMill и PowerMill Robot', 'FeatureCAM', 'PartMaker']}
                  />   */}
              </p>
              <SocialMedia />
              {/*<div className="button-greeting-div">
                <Button text="Список постпроцессоров" href="/postprocessors" />
                <Router>
                  <Switch>
                    <Route exact path="/postprocessors" component={Postprocessors} />
                  </Switch>
                </Router>


                {greeting.resumeLink && (
                  <Button
                    text="Резюме"
                    newTab={true}
                    href={greeting.resumeLink}
                  />
                )}
                </div>*/}
            </div>
          </div>
          <div className="greeting-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={titlePerson} />
            ) : (
              <img
                alt="man sitting on table"
                src={require("../../assets/images/manOnTable.svg")}
              ></img>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
